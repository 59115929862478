/* PIE */
.component-pie-canvas {
    padding: .5vw;
}

.component-pie-graph {
    background: rgb(34, 37, 46);
    transition: all .5s;
    border-radius: 10px;
}

.component-pie-graph h1 {
    color: white;
    font-size: 1.2rem;
    text-align: center;

    padding: 3vh 1vh;
}

.component-pie-graph p {
    padding-bottom: 2vh;
    color: white;
    margin: 0 1.5vh;
}