.block-component {
    margin: 2vh 1.5vh;
}

.block-component-title {
    font-size: .75rem;
    color: white;
}

.block-component-legend {
    font-size: .9rem;
    margin-bottom: .4vw;
    color: rgba(255, 255, 255, .5);
}

.block-component-content {
    background: rgb(34, 37, 46);
    transition: all .5s;
    border-radius: 10px;
}