.user-create-component {
    padding: 2vh;
    color: white;
}

.user-create-component input {
    padding: .4vh;
}

.user-create-component-button {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
}

.user-create-component-button button {
    padding: 1vh 0;
    font-size: 1rem;
    background-color: green;
    border-radius: 5px;
    width: 70%;
}

.user-create-component-plans span,
.user-create-component-groups span {
    color: red;
}

.user-create-component-groups div,
.user-create-component-plans div {
    display: flex;
    align-items: center;
    text-align: left;
}

.user-create-component-groups .component-input,
.user-create-component-plans .component-input {
    width: auto;
    height: auto;
}