.menu {
    background: #22252e;
    float: left;
    height: 100%;
    left: 0;
    position: fixed;
    width: 14vw;
}

.menu-header {
    padding: 3vh 1vh;
    text-align: center;
}

.menu-header img {
    width: 4.5vw;
}

.menu-content-profile {
    color: white;
    padding: 0 1vh;
    display: flex;
    align-items: center;
    font-size: .9rem;

    margin-bottom: 1vw;
}

.menu-content-profile img {
    height: 2.4vw;
    margin-right: 1vh;
    width: 20%;
    height: 48px;
}