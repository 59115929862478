.turma-content, .turma-content-students {
    padding: 2vh;
    margin: 2vh 1.5vh;
    background-color: #22252e;
    border-radius: 10px;
    color: white;
}

.turma-content-header-actions {
    display: flex;
    justify-content: right;
}

.turma-content-header-actions button {
    border-radius: 5px;
    text-align: center;
}

.turma-content-header-actions-button-message {
    background-color: hsla(0, 0%, 100%, .25);
    width: 48%;
    font-size: 1rem;
}

.turma-content-header-actions-button-update {
    background-color: hsla(0, 0%, 100%, .25);
    width: 30%;
    font-size: 1rem;
    margin-left: 8px;
}

.turma-content-header-actions-button-delete {
    padding: .7vh 0 0;
    width: 3.5vh;
    margin-left: 8px;
    font-size: 1.2rem;
    background-color: red;
}

.turma-content-header {
    text-align: center;
    padding: 0 2vh;
    margin: 2vh 1vh;
}

.turma-content-header-icon {
    font-size: 4.5rem;
    background-color: hsla(0,0%,100%,.25);
    padding: 2.5vh;
    padding-bottom: 0;
    margin: 1vh 10vh;
    border-radius: 10vh;
}

.turma-content-title {
    font-size: 1.4rem;
    margin: 2vh 0;
}

.turma-content-info {
    text-align: justify;
}

.turma-content-info .turmas-content-item-header-weekDay {
    margin: .2vh;
    font-size: .85rem;
}

.turma-content-students-title {
    border-bottom: 2.5px solid red;
    display: flex;
    justify-content: space-between;
}

.turma-content-students-button {
    width: 8%;
    height: 4%;

    border-radius: 10px;
    background-color: #179917;
    font-size: 1.2rem;
}

.turma-content-student-actions {
    display: flex;
    justify-content: space-between;
}

.turma-content-student {
    background-color: hsla(0,0%,100%,.25);
    border-radius: 10px;
    padding: 1.6vh .8vh;
    margin: 1vh 0;
}

.turma-content-student p {
    font-size: 1.3rem;
}

.turma-content-student-content {
    display: flex;
    margin-bottom: 1vh;
    overflow-y: auto;
}

.turma-content-student-content-icon {
    width: 17.5%;
    margin-right: 1.2vh;
}

.turma-content-student-content-info span {
    color: rgba(255, 255, 255, .5);
    font-size: 12.5px;
    width: 100%;
    display: block;
}

.turma-content-student-content img {
    width: 100%;
    border-radius: 10px;
}
 
.turma-content-student-actions-button,
.turma-content-student-actions-button-remove {
    color: white;
    width: 48%;
    margin-right: 2%;
    padding: .5vh;
    font-size: .9rem;
    border-radius: 5px;
}

.turma-content-student-actions-button-remove {
    background-color: aquamarine;
    color: black;
}

.turma-content-student-actions-button:first-child {
    background-color: #179917;
}

.turma-content-student-actions-button:last-child {
    margin: 0;
    background-color: rgb(246, 35, 35);
}

.turma-content-schedules {
    padding: 1.5vh;
    color: white;
}

.turma-content-schedule {
    padding: 1vh;
    margin: 1vh 0;
    border-radius: 5px;
    color: white;
    background: hsla(0,0%,100%,.25);
}

.turma-content-schedule-titles h3 {
    font-size: 1.2rem;
}

.turma-content-schedule-titles p {
    font-size: .85rem;
    color: rgba(255, 255, 255, .5);
}

.turma-content-schedule-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
}

.turma-content-schedule-button {
    width: 48%;
    border-radius: 5px;
    font-size: 15px;
    padding: .5vh;
}

@media screen and (min-width: 720px) {

    .turma-content-header-icon {
        margin: 0 13vh;
    }

    .turma-content-list {
        display: flex;
    }

    .turma-content {
        width: 30%;
        max-height: 575px;
    }

    .turma-content-students {
        width: 32%;
        max-height: 750px;
    }

    .turma-content-students-list {
        max-height: 71.5vh;
        overflow-y: auto;
    }

    .turma-content-block {
        width: 32%;
    }

    .turma-content-student-content-info p {
        font-size: 1.1rem;
    }

    .turma-content-schedules {
        max-height: 25vh;
        overflow-y: auto;
    }
}