.user-info-component {
    padding: 2vh;
    margin: 2vh 1.5vh;
    background-color: #22252e;
    border-radius: 10px;
    color: white;
}

/* HEADER */
.user-info-component-header {
    text-align: center;
    padding: 0 2vh;
    margin: 2vh 1vh;
}

.user-info-component-header-icon img {
    width: 45%;
    height: 140px;
    border-radius: 50%;
}

.user-info-component-header-name {
    font-size: 1.4rem;
}

.user-info-component-edit {
    display: flex;
    justify-content: end;
    margin-bottom: 1.5vh;
}

.user-info-component-edit-button {
    padding: 1vh;
    background-color: hsla(0,0%,100%,.25);
    width: 37.5%;
    border-radius: 10px;
    font-size: 1rem;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info-component-delete-button {
    width: 3.5vh;
    border-radius: 5px;
    padding: .5vh 0 0;
    font-size: 1.2rem;
    background-color: red;
    margin-left: 10px;
}

.user-info-component-edit-button svg {
    margin-right: .5vh;
    font-size: 1.2rem;
}

/* DESCRIPTION */
.user-info-component-description-group,
.user-info-component-description-module {
    background-color: hsla(0,0%,100%,.25);
    padding: .1vh .5vh;
    border-radius: 5px;
    margin-right: .5vh;
}

.user-info-component-description-socials {
    margin: 2vh 0;
    display: flex;
    justify-content: space-evenly;
}

.user-info-component-description-social {
    text-decoration: none;
    color: white;
    background-color: hsla(0,0%,100%,.25);
    padding: .5vh 1vh;
    border-radius: 10px;
    text-align: center;
}

.user-info-component-description-social span svg {
    font-size: .85rem;
    margin-right: .5vh;
}

@media screen and (min-width: 720px ) {
    
    .user-info-component {
        width: 27.5%;
        max-height: 74vh;
    }

}