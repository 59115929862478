.modules-contents {
    border-radius: 10px;
    background-color: #22252e;
    margin: 2vh 1.5vh;
}

.modules-contents-header {
    padding: 1.5vh 1.5vh 0;
    color: hsla(0,0%,100%,.75);
}

.modules-contents-header-text {
    margin: 1vh 0;
}

.modules-contents-header-button {
    display: flex;
    justify-content: left;
}

.modules-contents-header-button button {
    width: 35%;
    border-radius: 5px;
    padding: .8vh 0;
    font-size: 1rem;
    background-color: green;
}

.modules-contents-filter-button {
    background-color: #e21013;
    border-radius: 5px;
    max-width: 9vh;
    font-size: .9rem;
    padding: .4vh;
    margin-right: .8vh;
    transition: all .7s;
    text-align: center;
}

.modules-contents-filter-button-reset {
    background-color: hsla(0,0%,100%,.25);
    margin-left: 0;
}

.modules-contents-filter {
    display: flex;
    align-items: center;
}

.modules-contents-items {
    padding: 1.5vh;
    overflow-y: auto;
    max-height: 66vh;
}

.modules-contents-items-loading {
    color: white;
    text-align: center;
    padding: .5vh 0;
}

.modules-contents-item {
    background: hsla(0,0%,100%,.25);
    border-radius: .5em;
    margin: 1.5vh .2vh;
    padding: 1vh;
    min-height: 120px;
}

.modules-contents-item:first-child {
    margin-top: 0;
}

.modules-contents-item:last-child {
    margin-bottom: 0;
}

.modules-contents-item-header {
    font-size: .75rem;
    color: hsla(0,0%,100%,.65);
    display: flex;
    justify-content: space-between;
    margin-bottom: .5vh;
}

.modules-contents-item-title {
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: .5vh;
    font-size: 1.1rem;
}

.modules-contents-item-description {
    overflow-y: auto;
    max-height: 7vh;
    color: hsla(0,0%,100%,.65);
    font-size: .8rem;
    margin-bottom: 1.2vh;
}

.modules-contents-item-footer {
    margin: .8vh 0 0;
    display: flex;
    justify-content: right;
}

.modules-contents-item-footer-button {
    width: 12vh;
    background: #179917;
    border-radius: 5px;
}

.modules-info-content {
    padding: 2vh;
    margin: 2vh 1.5vh;
    background-color: #22252e;
    border-radius: 10px;
    color: white;
}

.modules-info-content-header {
    text-align: center;
    padding: 0 2vh;
    margin: 2vh 1vh;
}

.modules-info-content-header-icon {
    font-size: 4.5rem;
    background-color: hsla(0,0%,100%,.25);
    padding: 2.5vh;
    padding-bottom: 0;
    margin: 1vh 10vh;
    border-radius: 10vh;
}

.modules-info-content-title {
    font-size: 1.4rem;
    margin: 2vh 0;
}

.modules-info-content-info {
    text-align: justify;
}

.modules-info-content-actions {
    display: flex;
    justify-content: right;
}

.modules-info-content-actions button {
    width: 3.5vh;
    border-radius: 5px;
    padding: .5vh 0 0;
    font-size: 1.2rem;
    background-color: red;
}

.modules-info-content-icons {
    display: flex;
    justify-content: baseline;
}

.modules-info-content-icons svg {
    width: 2vh;
    height: 2vh;
    margin: .4vh;
    cursor: pointer;
}

.modules-content-actions {
    padding: 1.5vh 1vh;
    display: flex;
    justify-content: right;
}

.modules-content-actions button {
    background-color: #179917;
    width: 35%;
    padding: .8vh;
    font-size: .9rem;
    border-radius: 5px;
}

@media screen and (min-width: 720px) {
    
    .modules-info-conents-block {
        max-height: 82vh;
        overflow-y: auto;
    }
    
    .modules-contents-header-button button {
        width: 10%;
    }

    .modules-contents-items {
        display: flex;
        flex-wrap: wrap;
    }

    .modules-contents-item {
        width: 24.395%;
        margin-right: .8vh;
        margin-bottom: 0;
    }

    .modules-info {
        display: flex;
        justify-content: left;
    }

    .modules-info-content {
        width: 25%;
    }

    .modules-info-graph {
        width: 32%;
    }

    .modules-info-clases {
        width: 40%;
        max-height: 500px;
    }

    .modules-info-clases .modules-content-actions button {
        width: 25%;
    }

    .modules-content-actions button {
        width: 20%;
    }

    .modules-contents-filter-button {
        margin-right: .5vh;
    }

    .modules-classes-and-students {
        display: flex;
    }

    .modules-classes, .modules-students {
        width: 49%;
    }

}