.dashboard-sub-menus {
    display: inline-flex;
    align-items: center;

    max-width: 95.5vw;
    margin: .5vw 1.5vh;
    overflow-x: auto;

    background: rgb(34, 37, 46);
    border-radius: 10px;
}

/* SUB MENU ITEM */
.dashboard-sub-menu-item {
    display: inline-flex;
    color: white;

    padding: .5vh 1vh;
    margin: 1vh 2vh;
    cursor: pointer;
    font-size: 1.1rem;
    text-align: center;

    transition: all .7s;
}

.dashboard-sub-menu-item span {
    font-size: 1.2rem;
    margin-right: 1vh;
}

.dashboard-sub-menu-item:hover {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 5px;
}

/* SUB MENU ITEM LINE */
.dashboard-sub-menu-item-line {
    border-bottom: 2.5px solid red;
}

/* SUB MENU ITEM ICON */
.dashboard-sub-menu-item-icon {
    color: white;

    margin-top: .1vw;
    margin-right: .5vw;
}

@media screen and (min-width: 720px) {
    
    .dashboard-sub-menu-item {
        padding: .75vh 1vh;
        margin: 0 1.5vh;
        font-size: 1rem;
    }

}