.turmas-content {
    margin: 2vh 1.5vh;
    padding: 1.5vh;

    background-color: #22252e;
    border-radius: 10px;
}

.turmas-content-header {
    margin-bottom: 1.5vh;
}

.turmas-content-header-button {
    display: flex;
    justify-content: left;
}

.turmas-content-subtitle {
    color: hsla(0,0%,100%,.75);
    margin: 1vh 0;
}

.turmas-content-filter {
    display: flex;
    align-items: center;
}

.turmas-content-filter-button {
    background-color: #e21013;
    border-radius: 5px;
    max-width: 9vh;
    font-size: .9rem;
    padding: .4vh;
    margin-right: .8vh;
    transition: all .7s;
    text-align: center;
}

.turmas-content-filter-button-reset {
    background-color: hsla(0,0%,100%,.25);
    margin-left: 0;
}

/* TURMAS ITEM */
.turmas-content-items {
    max-height: 66vh;
    overflow-y: auto;
}

.turmas-content-items-loading {
    text-align: center;
    color: white;
    margin: 2vh 0;
    margin-top: 1vh;
}

.turmas-content-item {
    background: hsla(0,0%,100%,.25);
    border-radius: .5em;
    margin: 1.5vh .2vh;
    padding: 1vh;

    height: 145px;
}

.turmas-content-item:first-child {
    margin-top: 0;
}

.turmas-content-item-header {
    font-size: .9rem;
    color: hsla(0,0%,100%,.65);
    display: flex;
    justify-content: space-between;
}

.turmas-content-item-header-days span:first-child {
    margin-left: 0;
}

.turmas-content-item-header-days span {
    margin: 0 .2vh;
}

.turmas-content-item-header-weekDay {
    background: hsla(0,0%,100%,.3);
    color: #fff;
    padding: .1vh .6vh;
    border-radius: 5px;
}

.turmas-content-item-title {
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin: .8vh 0;
    margin-bottom: .4vh;
}

.turmas-content-item-title span {
    font-size: .8rem;
}

.turmas-content-item-description {
    overflow-y: auto;
    height: 4vh;
    color: hsla(0,0%,100%,.65);
    font-size: .8rem;
    margin-bottom: 1.2vh;
}

.turmas-content-item-footer {
    margin: .8vh 0;
    display: flex;
    justify-content: right;
}

.turmas-content-item-footer-button {
    width: 12vh;
    background: #179917;
    border-radius: 5px;
}

.turmas-content-button {
    width: 35%;
    border-radius: 5px;
    padding: .8vh 0;
    font-size: 1rem;
    background-color: green;
}

@media screen and (min-width: 720px) {
    
    .turmas-content-header {
        margin: 0;
    }

    .turmas-content-button {
        width: 10%;
    }

    .turmas-content-items {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    .turmas-content-item {
        width: 24.395%;
        margin-right: .8vh;
        margin-bottom: 0;
    }
    
    .turmas-content-item-description {
        max-height: 10vh;
    }

}