.notifications-status {
    padding: 1vh 1.5vh;
    color: white;
}

.notifications-status-api {
    background-color: hsla(0,0%,100%,.25);
    border-radius: 5px;
    padding: 1vh;
    margin: 1vh 0;
    display: flex;
}

.notifications-status-api p {
    margin: 0 .5vh;
}

.notifications-status-api svg {
    color: green;
    font-size: 1.5rem;
}

.notifications-actions button {
    border-radius: 5px;
    background-color: green;
    font-size: 1rem;
    padding: .5vh;
    margin: 1vh 0;
}

.notifications-actions-list {
    display: flex;
    justify-content: space-between;
}

.notifications-actions-list button {
    width: 48%;
}

.notifications-qrcode {
    display: flex;
    justify-content: center;
    margin: 2vh 0;
}

.notifications-historic {
    color: white;
}

.notifications-historic-content {
    display: flex;
    justify-content: space-between;
    margin: 2vh;
    background-color: red;
}

.notifications-historic-content p {
    width: 10%;
    overflow-x: scroll;
}

@media screen and (min-width: 720px) {
    
    .notifications-graphs {
        display: flex;
    }

    .notifications-graphs-status {
        width: 20vw;
    }

    .notifications-graphs-historic {
        width: 70vw;
    }

}