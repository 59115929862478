@font-face {
    font-family: "Poppins";
    src: url("./files/fonts/poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 300;
}

html {
    position: relative;
    height: 100%;
    background-color: #2e313a!important;
}

strong {
    font-weight: bold;
}

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, .25);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, .25);
    border-radius: 10px;
}

/* COLORS */
.component-color-gold {
    background-color: #F2AE30;
}

.text-red {
    color: red;
}

.text-green {
    color: green;
}

/* DASHBOARD */
.dashboard {
    width: 100%;
}

.dashboard-welcome {
    margin: 1vh 1.5vh;
    color: white;
    font-size: .9rem;
    display: flex;
}

.dashboard-welcome h1 {
    border-bottom: 2px solid red;
}

.dashboard-admin {
    margin: 2vh 1.5vh;
}

.dashboard-admin-users-reports,
.dashboard-admin-invoices-reports {
    margin-bottom: 1.2vh;
}

.dashboard-back {
    color: white;
    padding: .5vh 1.5vh;
    font-size: 1rem;
    padding-bottom: 0;

    display: flex;
    cursor: pointer;
}

.dashboard-back p {
    text-decoration: underline;
}

/* DASHBOARD-CONTENT */
.dashboard-content {
    min-width: 85vw;
    position: absolute;
    right: 0;
    width: 86vw;
    padding: 2.5vh;
}

/* DASHBOARD SUB MENU ACTIONS */
.dashboard-sub-menu-actions {
    float: right;
    margin: .5vw 1.5vw;
    display: inline-flex;
    justify-content: space-between;
}

/* Dashboard User Menu */
.dashboard-user-menu {
    float: right;
    width: 14.45vw;
    height: 100%;
}

/* DASHBOARD ITENS */
.dashboard-itens {
    background: rgb(34, 37, 46);

    margin: 0 2.7vw;
    padding-bottom: 2vh;
    border-radius: 10px;
}

/* GRAPH */
.component-graph-title {
    font-size: .75rem;
    color: white;
}

.component-graph-legend {
    font-size: .9rem;
    margin-bottom: .4vw;

    color: rgba(255, 255, 255, .5);
}

.swal2-html-container {
    overflow: hidden!important;
}