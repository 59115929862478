.configurations-user-info-content-update-info {
    display: flex;
    justify-content: center;
}

.configurations-user-info-content-update-info-button {
    width: 80%;
    border-radius: 10px;
    font-size: 1.1rem;
    padding: 1vh;
    background-color: cornflowerblue;
}

.configurations-user-info-content-frequency,
.configurations-user-info-content-update-info {
    margin: 2vh 1.5vh;
}

/* Invoice */
.configurations-user-info-content-invoice {
    padding: 1vh 1.5vh;
    color: white;
}

.configurations-user-info-content-invoice-price {
    font-size: 2.25rem;
}

.configurations-user-info-content-invoice-legend {
    margin-top: -1vh;
    color: rgba(255, 255, 255, .5)
}

.configurations-user-info-content-invoice-actions {
    display: flex;
    justify-content: space-between;
}

.configurations-user-info-content-invoice-actions button {
    margin: 1vh 0;
    padding: .5vh 0;
    font-size: 1em;
    width: 18vh;
    border-radius: 5px;
}

/* INVOICES */
.configurations-user-info-content-invoices {
    color: white;
    padding: .5vh 1vh;
    max-height: 25vh;
    overflow-y: auto;
}

.configurations-user-info-content-invoices-content {
    background-color: hsla(0,0%,100%,.25);
    margin: 1vh 0;
    padding: .5vh 1vh;
    font-size: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.configurations-user-info-content-invoices-content-others {
    display: flex;
    font-size: .75rem;
}

.configurations-user-info-content-invoices-content-others span {
    margin-right: .4vh;
    background-color: rgb(34, 37, 46);
    padding: .4vh .8vh;
    border-radius: 5px;
}

@media screen and (min-width: 720px) {
    
    .configurations-user-block {
        display: flex;
    }

    .configurations-user-other-block {
        width: 27.5%;
    }

    .configurations-user-invoices-block {
        width: 42%;
    }

    .configurations-user-info-content-invoices {
        max-height: 71vh!important;
    }
}