.configurations-user-content {
    border-radius: 10px;
    background-color: #22252e;
    margin: 2vh 1.5vh;
    padding: 1.5vh;
}

.configurations-user-content-header {
    margin-bottom: 1.5vh;
}

.configurations-user-content-subtitle {
    margin: 1vh 0;
    color: hsla(0,0%,100%,.75);
}

.configurations-user-content-items {
    overflow-y: auto;
    max-height: 66vh;
}

.configurations-user-content-item {
    background-color: hsla(0,0%,100%,.25);
    border-radius: 10px;
    padding: 1.6vh .8vh;
    margin: 1vh 0;
    color: white;
}

.configurations-user-content-item:first-child {
    margin-top: 0;
}

.configurations-user-content-name {
    display: flex;
    margin-bottom: 1vh;
    overflow-y: auto;
    font-size: 1.2rem;
}

.configurations-user-content-name p {
    max-height: 5vh;
}

.configurations-user-content-name img {
    display: block;
    width: 15%;
    height: 48px;
    margin-right: 2%;
    border-radius: 3vh;
}

.configurations-user-content-infos {
    font-size: .75rem;
    margin: 1vh 0;
    height: 80px;
    max-height: 80px;
    overflow: auto;
}

.configurations-user-content-action {
    color: white;
    background-color: hsla(0,0%,100%,.3);
    width: 45%;
    margin-left: 55%;
    padding: .5vh;
    font-size: .9rem;
    border-radius: 5px;
}

.configurations-user-content-filter {
    display: flex;
    align-items: center;
}

.configurations-user-content-filter-button {
    background-color: #e21013;
    border-radius: 5px;
    max-width: 9vh;
    font-size: .9rem;
    padding: .4vh;
    margin-right: .8vh;
    transition: all .7s;
    text-align: center;
}

.configurations-user-content-filter-button-reset {
    background-color: hsla(0,0%,100%,.25);
    margin-left: 0;
}

.configurations-user-content-button {
    display: flex;
    justify-content: left;
}

.configurations-user-content-button button {
    width: 35%;
    border-radius: 5px;
    padding: .8vh 0;
    font-size: 1rem;
    background-color: green;
}

@media screen and (min-width: 720px) {
    
    .configurations-user-content-button button {
        width: 10%;
    }

    .configurations-user-content-items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -.5vh;
    }

    .configurations-user-content-item {
        width: 24.395%;
        height: 200px;
        margin: .5vh!important;
    }

}