/* filter STYLE */
.component-filter-style {
    color: white;
    background: rgba(255, 255, 255, .25);
    border-radius: .3rem;
    margin-right: .7vw;

    transition: all 1s;
}

.component-filter-style:hover {
    box-shadow: 0 0 5px rgba(255, 255, 255, .5);
    border-radius: .3rem;
}

/* filter SELECT */
.component-filter-select {
    padding: .5vh 1vh;
    font-size: .8rem;
}

.component-filter-select option {
    color: black;
    background: white;
}

/* filter INPUT */
.component-filter-input {
    padding: .35vh;
    font-size: .85rem;
}

.component-filter-input::placeholder {
    color: hsla(0,0%,100%,.75);
    padding-left: .5vh;
}

/* filter ITEM */
.component-filter-item {
    display: inline-flex;
    align-items: center;
    margin-right: .8vh;
}

/* filter ITEM ICON */
.component-filter-item-icon {
    color: white;
    margin-top: .45vw;
}

.component-filter-item-icon span {
    font-size: 1.4vw;
}

.component-filter-icon-left {
    margin-right: .4vw;
    margin-left: -.2vw;
}

.component-filter-icon-right {
    margin-left: -.3vw;
}

/* filter DATE */
.component-filter-date {
    padding: .08vw .2vw;
    font-size: .88rem;
    cursor: pointer;
}

.component-filter-date {
    cursor: pointer;
}

.react-calendar__tile--active {
    background: var(--cor-imarketing);
}

.react-daterange-picker__wrapper {
    border: none;
}

.react-calendar__month-view__weekdays__weekday {
    color: black;
}

.react-daterange-picker__inputGroup__input,
.react-date-picker__inputGroup__divider,
.react-daterange-picker__button__icon {
    color: white;
    stroke: white;
}

@media screen and (min-width: 720px) {
    
    .component-filter-select,
    .component-filter-input {
        width: 200px;
        margin-right: 0;
    }

}