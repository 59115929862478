/* BAR */
.component-bar-canvas {
    padding: .5vw;
}

.component-bar-graph {
    padding: 2vh 1vh;
    background: rgb(34, 37, 46);
    transition: all .5s;
    border-radius: 10px;
}

.component-bar-graph h1 {
    color: white;
    font-size: 1.2rem;
    text-align: center;

    padding: 3vh 1vh;
}

.component-bar-graph p {
    padding-bottom: 2vh;
    color: white;
    margin: 0 1.5vh;
}