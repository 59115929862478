.configurations-plans {
    border-radius: 10px;
    background-color: #22252e;
    margin: 2vh 1.5vh;
}

.configurations-plans-content {
    padding: 1.5vh;
}

.configurations-plans-content-icons {
    display: flex;
    justify-content: baseline;
}

.configurations-plans-content-icons svg {
    width: 2vh;
    height: 2vh;
    margin: .4vh;
    cursor: pointer;
}

.configurations-plans-content-button {
    display: flex;
    justify-content: left;
}

.configurations-plans-content-button button {
    width: 35%;
    border-radius: 5px;
    padding: .8vh 0;
    font-size: 1rem;
    background-color: green;
}

@media screen and (min-width: 720px) {
    
    .configurations-plans-content-button button {
        width: 10%;
    }

}