.login-align-itens {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 25px;

    background-image: 
        linear-gradient( #252525, #000000E0 100%),
        url("../../files//images/background.png");
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.login-box {
    width: 475px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 30px;
}

/* BOX HEADER */
.login-box-header img {
    display: block;
    margin: 0 auto;
    width: 16%;
}

/* BOX CONTENT */
.login-box-content {
    border-color: red;
    margin-top: 1.4vw;
}

.login-box-content-division {
    margin: 3.5vh 0;
    display: flex;
    align-items: center;
}

.login-box-content-division p {
    margin: 0 1vh;
    font-size: 12px;
    font-weight: bold;
}

.login-box-content-line {
    width: 100%;
    border-bottom: 2px solid black;
}

.login-box-content h2 {
    font-weight: bold;
    margin-top: 1vw;
    text-align: center;
}

.login-box-content-section h3 {
    /* font-weight: bold; */
    text-align: center;
}

/* FOOTER */
.login-box-footer {
    text-align: center;
    margin: 1.3vw 0;
    font-size: .75rem;
}

.login-box-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}

.login-box-actions a {
    font-size: .8rem;
    color: black;
}