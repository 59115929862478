.subitem-component {
    cursor: pointer;

    display: flex;
    color: white;
    font-size: 1.1rem;
    margin: 1.5vh 2vh;
    padding: 1vh;
    border-radius: 10px;
    text-align: center;
    transition: all .8s;

    padding-bottom: .75vh;
}

.subitem-component-line-separator {
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    margin: 0 .5vh;
}

.subitem-component-transparent, .subitem-component:hover {
    background-color: hsla(0,0%,100%,.1);
}

.subitem-component span {
   font-size: 1.2rem;
   margin-left: 1vh;
}

.subitem-component p {
    margin-left: 1vh;
}

.subitem-component-line {
    background-color: #e21013;
    border-radius: 2.5px;
    width: 1.4vw;

    margin-left: auto;
    margin-right: -1vh;
}

@media screen and (min-width: 720px) {

    .subitem-component {
        font-size: 1rem;
        margin: 1vh;
        padding-bottom: .5vh;
    }
    
    .subitem-component-line {
        width: .3vw;
    }

}