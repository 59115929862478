.user-update-component {
    padding: 2vh;
    color: white;
}

.user-update-component input {
    padding: .4vh;
}

.user-update-component-button {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
}

.user-update-component-button button {
    padding: 1vh 0;
    font-size: 1rem;
    background-color: green;
    border-radius: 5px;
    width: 70%;
}

@media screen and (min-width: 720px) {

    .user-update-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}