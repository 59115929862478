.menu-component-icon-content, .menu-component-sidebar-icons {
    color: white;
    font-size: 2.5rem;
    margin: 2vh 1vh;
}

.menu-component-icons {
    display: flex;
    justify-content: space-between;
}

.menu-component-sidebar-icons {
    display: flex;
    justify-content: space-between;
}

.menu-component-sidebar-icons button {
    width: 32.5%;
    border-radius: 5px;
    background: hsla(0,0%,100%,.25);
    font-size: 1.1rem;
    margin: .4vh 0;
    transition: all 1s;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.menu-component-sidebar-icons button svg {
    font-size: 1.2rem;
}

.menu-component-sidebar-icons button:hover {
    background: #e21013;
}

.menu-component-sidebar-icon {
    font-size: 3.2rem;
}

.menu-component-sidebar {
    background-color: #171923;
    position: fixed;
    height: 100%;
    top: 0px;
    left: 0px;
    width: 65vw;
    animation: showSidebar .4s;
    z-index: 3;
}

.menu-component-sidebar-profile {
    color: white;
    margin: 2vh 1vh;

    display: flex;
    align-items: center;
}

.menu-component-sidebar-profile img {
    width: 13%;
    margin-right: .8vh;
}

.menu-component-sidebar-profile p {
    font-size: 1.1rem;
    overflow-y: auto;
}

@keyframes showSidebar {
    from {
        opacity: 0;
        width: 0;
    }
    to {
        opacity: 1;
        width: 65vw;
    }
}

@media screen and (min-width: 720px) {

    .menu-component-icons {
        display: none;
    }
    
    .menu-component-sidebar {
        width: 12vw;
        position: fixed;
        height: 100%;
        animation: none;
        z-index: inherit;
    }

}