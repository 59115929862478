.register-buton-action-back {
    display: flex;
    justify-content: space-between;
}

.register-button-back {
    width: 8vh;

    margin-top: 2.5vh;
    padding: 1vh;
    border-radius: 10px;
    font-size: 1rem;
    background-color: #2a79bd;
}

.register-button-next {
    width: 18vh;

    margin-top: 2.5vh;
    padding: 1vh;
    border-radius: 10px;
    font-size: 1rem;
    background-color: #2a79bd;
}

.plan-box-select {
    z-index: 2;
    background-color: rgba(0, 0, 0, .1);
    padding: 1vh;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
}

.plan-box-select:last-child {
    margin-bottom: 0;
}

.plan-box-select-price {
    text-align: center;
    font-size: 1.2rem;
}

.plan-box-selected {
    border: 1.5px solid green;
}

.register-show-info p {
    margin: 0;
}

.plan-box-select-info p {
    margin: 0;
    font-size: .9rem;
}

.plans-list {
    max-height: 350px;
    overflow-y: auto;
}