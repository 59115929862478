@media screen and (min-width: 720px) {

    .dashboard-admin,
    .dashboard-user {
        overflow-y: auto;
        height: 77vh;
    }

    .dashboard-user {
        display: flex;
    }

    .dashboard-user-block {
        width: 22.5vw;
    }

    .menu-component-icon {
        background: #22252e;
        float: left;
        height: 100%;
        left: 0;
        position: fixed;
        width: 12vw;
    }

    .dashboard-sub-menu {
        display: inline-flex;
        justify-content: space-between;
    }

    .dashboard-sub-menus {
        overflow-x: auto;
        align-items: center;
        background: #22252e;
        border-radius: 10px;
        display: inline-flex;
        margin: .5vw 1.5vh;
        padding: .5vh 0;
    }

    .dashboard-admin-content {
        display: flex;
    }
    
    .dashboard-admin-users-reports,
    .dashboard-admin-invoices-reports {
        width: 20vw;
        margin-right: 2vw;
    }

    .dashboard-admin-invoices-reports {
        height: 5vh;
    }
    
}