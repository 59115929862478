/* INPUT COMPONENTS */
.input-components {
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    margin-top: 15px;

    height: 45px;
    width: 100%;
}

.input-components input {
    width: 90%;
    height: 100%;
    border-radius: 0 10px 10px 0;
    padding-left: 10px;
    font-size: 13px;
}

.input-components span {
    float: left;
    height: 45px;
    width: 10%;
    border-right: 1px solid #d8d8d8;
}

.input-components svg {
    font-size: 20px;
    display: block;
    margin: 12px auto auto;
}


/* COMPONENT INPUT */
.component-input {
    height: 45px;
    width: 100%;
    padding-left: 15px;

    border: 1px solid #d8d8d8;
    border-radius: 10px;
}

/* COMPONENT INPUT LABEL */
.input-components-label {
    margin-top: 1vh;
}

.input-components-label input[type="file"] {
    width: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.input-components-label input[type="file"]::file-selector-button {
    background-color: #e5e5e5;
    color: #000;
    border: 0px;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s;
}

.component-input-label {
    font-size: 13px;
    margin-left: 6px;
}